import { defineComponent, computed, useFetch, } from '@nuxtjs/composition-api';
import { HaveAQuestion, InstallVideo } from '~/modules/b2b/components/beast';
import { HaveAQuestionDefaults, IntallIstructions } from '~/composables/useContentful/defaults';
import { useContentful } from '~/composables';
import { QUEST_GOOSE_BUNDLE_SKU } from '~/modules/b2b/constants.client';
import { useB2BUiState } from '~/modules/b2b/composables';
export default defineComponent({
    name: 'GooseInstall',
    components: { HaveAQuestion, InstallVideo },
    layout: 'clean',
    setup() {
        const { search, content } = useContentful('install-instructions-goose');
        const { goTo } = useB2BUiState();
        useFetch(async () => {
            await search({
                url: 'install-instructions-goose',
            });
        });
        const HaveAQuestionProps = computed(() => {
            var _a, _b, _c, _d;
            return ((_d = (_c = (_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.find((item) => item.fields.id === 'have-a-question')) === null || _d === void 0 ? void 0 : _d.fields) || HaveAQuestionDefaults;
        });
        const title = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.title) || 'Lens installation'; });
        const Instructions = computed(() => {
            var _a, _b, _c, _d;
            return ((_d = (_c = (_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.find((item) => item.fields.id === 'install-instructions-goose')) === null || _d === void 0 ? void 0 : _d.fields) || IntallIstructions;
        });
        return {
            QUEST_GOOSE_BUNDLE_SKU,
            HaveAQuestionProps,
            title,
            goTo,
            Instructions,
        };
    },
    head() {
        return {
            title: this.title,
        };
    },
});
